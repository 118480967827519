import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	changePage: '@workOrder/CHANGE_PAGE',
	paginationChange: '@workOrder/PAGINATION_CHANGE',
	resetState: '@workOrder/RESET_STATE',
	apiFilter: '@workOrder/API_FILTER',
	getApi: '@workOrder/GET_API',
	getCountApi: '@workOrder/GET_COUNT_API',
	getSingleApi: '@workOrder/GET_SINGLE_API',
	createApi: '@workOrder/CREATE_API',
	updateApi: '@workOrder/UPDATE_API',
	terminateApi: '@workOrder/TERMINATE_API',
};

const url = lbBaseUrl + '/work-orders';
const milestoneUrl = lbBaseUrl + '/payment-milestones';

export const changePage = (pageType: string) => ({
	type: actionTypes.changePage,
	payload: pageType,
	meta: {},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const reset = () => ({
	type: actionTypes.resetState,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number, filter: any = {}) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(
		`${url}/${id}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const approve = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/approve`, data, getHeaders(true)),
	meta: {process: true},
});

export const cancel = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}/cancel`, data, getHeaders(true)),
	meta: {process: true},
});

export const approveAll = (ids: number[]) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(
		`${url}/approve-all?ids=${ids.join(',')}`,
		{},
		getHeaders(true),
	),
	meta: {process: true},
});

export const cancelAll = (ids: number[], data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(
		`${url}/cancel-all?ids=${ids.join(',')}`,
		data,
		getHeaders(true),
	),
	meta: {process: true},
});

export const terminate = (id: number, data: any) => ({
	type: actionTypes.terminateApi,
	payload: axios.patch(`${url}/${id}/terminate`, data, getHeaders(true)),
	meta: {process: true},
});

export const removeMilestone = (id: number): any => ({
	type: actionTypes.updateApi,
	payload: axios.delete(`${milestoneUrl}/${id}`, getHeaders(true)),
	meta: {process: true},
});
