import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCurrencyString, getFormattedDateMonth, getS3Url, getStartEndDate} from 'app/helpers';
import type Types from 'MyTypes';
import {Button, Card, Col, DatePicker, Row, Space, Spin, Table} from 'antd';
import {log} from 'app/services/logger-service';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {get, setFilterValue} from './actions';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {type ReportCounts, type WorkOrderBillReport, SiteStatus, type Site} from 'app/models';
import {pdfReportFile} from 'app/constants';

type Props = {
	reportType: string;
};

export const ViewWorkOrderBillReport: React.FC<Props> = ({reportType}) => {
	const dispatch = useDispatch();
	const {
		recordSiteStatus = {},
		filterValue,
		dataUpdated,
		loading,
		reportDate,
	} = useSelector((state: Types.RootState) => state.workOrderBillReport);

	const {Column, ColumnGroup} = Table;

	React.useEffect(() => {
		log('ViewWorkOrderBillReport.componentDidMount');
		filterData();
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			filterData();
		}
	}, [dataUpdated]);

	const filterData = (date: string | undefined = undefined) => {
		if (!date) {
			date = new Date().toString();
		}

		const filter = {
			where: {
				and: getQueryDateRangeForDays(date, -2, 4, 'createdAt'),
			},
			limit: 500,
			skip: 0,
		};
		dispatch(setFilterValue({date}));
		dispatch(get(filter));
	};

	const onWeakChange = (weakString: string) => {
		const splitWeak = weakString.split('-');
		const year = parseInt(splitWeak[0], 10);
		const weak = parseInt(splitWeak[1], 10);
		const date = moment().day('Wednesday').year(year).week(weak).toDate().toString();
		console.log({year, weak, date});
		filterData(date);
	};

	const [firstDay, lastDay] = getStartEndDate(reportDate ?? filterValue?.date as string)
		.map((date: string) => date ? getFormattedDateMonth(date) : '');

	const inWeek = `${lastDay} to ${firstDay}`;
	const inTotal = `Till ${firstDay}`;

	const initialReport: WorkOrderBillReport = {id: 0, siteId: 0, createdAt: ''};

	// eslint-disable-next-line complexity
	const reduceFn = (totalSum: WorkOrderBillReport, report: WorkOrderBillReport) => {
		if (!totalSum.site) {
			totalSum.site = {id: 0, name: 'Total', address: '', projectNumber: 0};
		}

		if (!totalSum.approvedBillCounts) {
			totalSum.approvedBillCounts = {total: 0, week: 0};
		}

		totalSum.approvedBillCounts.total += (report?.approvedBillCounts?.total ?? 0);
		totalSum.approvedBillCounts.week += (report?.approvedBillCounts?.week ?? 0);

		if (!totalSum.pendingBillCounts) {
			totalSum.pendingBillCounts = {total: 0, week: 0};
		}

		totalSum.pendingBillCounts.total += (report?.pendingBillCounts?.total ?? 0);
		totalSum.pendingBillCounts.week += (report?.pendingBillCounts?.week ?? 0);

		if (!totalSum.pendingBillAmounts) {
			totalSum.pendingBillAmounts = {total: 0, week: 0};
		}

		totalSum.pendingBillAmounts.total += (report?.pendingBillAmounts?.total ?? 0);
		totalSum.pendingBillAmounts.week += (report?.pendingBillAmounts?.week ?? 0);

		if (!totalSum.billAmounts) {
			totalSum.billAmounts = {total: 0, week: 0};
		}

		totalSum.billAmounts.total += (report?.billAmounts?.total ?? 0);
		totalSum.billAmounts.week += (report?.billAmounts?.week ?? 0);

		if (!totalSum.penaltyAmounts) {
			totalSum.penaltyAmounts = {total: 0, week: 0};
		}

		totalSum.penaltyAmounts.total += (report?.penaltyAmounts?.total ?? 0);
		totalSum.penaltyAmounts.week += (report?.penaltyAmounts?.week ?? 0);

		if (!totalSum.debitAmounts) {
			totalSum.debitAmounts = {total: 0, week: 0};
		}

		totalSum.debitAmounts.total += (report?.debitAmounts?.total ?? 0);
		totalSum.debitAmounts.week += (report?.debitAmounts?.week ?? 0);

		if (!totalSum.extraAmounts) {
			totalSum.extraAmounts = {total: 0, week: 0};
		}

		totalSum.extraAmounts.total += (report?.extraAmounts?.total ?? 0);
		totalSum.extraAmounts.week += (report?.extraAmounts?.week ?? 0);

		if (!totalSum.advanceAmounts) {
			totalSum.advanceAmounts = {total: 0, week: 0};
		}

		totalSum.advanceAmounts.total += (report?.advanceAmounts?.total ?? 0);
		totalSum.advanceAmounts.week += (report?.advanceAmounts?.week ?? 0);

		if (!totalSum.recoveredAdvanceAmounts) {
			totalSum.recoveredAdvanceAmounts = {total: 0, week: 0};
		}

		totalSum.recoveredAdvanceAmounts.total += (report?.recoveredAdvanceAmounts?.total ?? 0);
		totalSum.recoveredAdvanceAmounts.week += (report?.recoveredAdvanceAmounts?.week ?? 0);

		if (!totalSum.scaffoldingAmounts) {
			totalSum.scaffoldingAmounts = {total: 0, week: 0};
		}

		totalSum.scaffoldingAmounts.total += (report?.scaffoldingAmounts?.total ?? 0);
		totalSum.scaffoldingAmounts.week += (report?.scaffoldingAmounts?.week ?? 0);

		if (!totalSum.malwaAmounts) {
			totalSum.malwaAmounts = {total: 0, week: 0};
		}

		totalSum.malwaAmounts.total += (report?.malwaAmounts?.total ?? 0);
		totalSum.malwaAmounts.week += (report?.malwaAmounts?.week ?? 0);

		return totalSum;
	};

	const underConstructionLabel = {...initialReport};
	underConstructionLabel.site = {id: 0, name: 'Under Construction', address: '', projectNumber: 0};
	const underConstruction: WorkOrderBillReport[] = recordSiteStatus[SiteStatus.UNDER_CONSTRUCTION] ?? [];
	const underConstructionTotal: WorkOrderBillReport = underConstruction
		.reduce(reduceFn, {...initialReport});

	const underWarrantyLabel = {...initialReport};
	underWarrantyLabel.site = {id: 0, name: 'Under Warranty', address: '', projectNumber: 0};
	const underWarranty: WorkOrderBillReport[] = recordSiteStatus[SiteStatus.UNDER_WARRANTY] ?? [];
	const underWarrantyTotal: WorkOrderBillReport = underWarranty
		.reduce(reduceFn, {...initialReport});

	const underDesignLabel = {...initialReport};
	underDesignLabel.site = {id: 0, name: 'Under Design', address: '', projectNumber: 0};
	const underDesign: WorkOrderBillReport[] = recordSiteStatus[SiteStatus.UNDER_DESIGN] ?? [];
	const underDesignTotal: WorkOrderBillReport = underDesign
		.reduce(reduceFn, {...initialReport});

	const dataSource: WorkOrderBillReport[] = [
		underConstructionLabel,
		...underConstruction,
		underConstructionTotal,
		initialReport,
		underWarrantyLabel,
		...underWarranty,
		underWarrantyTotal,
		initialReport,
		underDesignLabel,
		...underDesign,
		underDesignTotal,
	];

	const csvData: any[] = [
		reportType === 'ttd' ? [
			'Site',
			'Week',
			'Pending Bill Amounts ' + inTotal,
			'Approved Bill Amounts ' + inTotal,
			'Penalty Amounts ' + inTotal,
			'Debit Amounts ' + inTotal,
			'Bill Without Work Order ' + inTotal,
			'Advance Amounts ' + inTotal,
			'Recovered Advance Amounts ' + inTotal,
			'Scaffolding Bill Amounts ' + inTotal,
			'Malwa Bill Amounts ' + inTotal,
		] : [
			'Site',
			'Week',
			'Pending Bill Amounts ' + inWeek,
			'Approved Bill Amounts ' + inWeek,
			'Penalty Amounts ' + inWeek,
			'Debit Amounts ' + inWeek,
			'Bill Without Work Order ' + inWeek,
			'Advance Amounts ' + inWeek,
			'Recovered Advance Amounts ' + inWeek,
			'Scaffolding Bill Amounts ' + inWeek,
			'Malwa Bill Amounts ' + inWeek,
		],
	];
	// eslint-disable-next-line complexity
	dataSource.forEach((record: WorkOrderBillReport) => {
		csvData.push(reportType === 'ttd' ? [
			record.site ? record.site.name : 'N/A',
			getFormattedDateMonth(record.createdAt),
			record.pendingBillAmounts?.total ? String(record.pendingBillAmounts?.total) : '',
			record.billAmounts?.total ? String(record.billAmounts?.total) : '',
			record.penaltyAmounts?.total ? String(record.penaltyAmounts?.total) : '',
			record.debitAmounts?.total ? String(record.debitAmounts?.total) : '',
			record.extraAmounts?.total ? String(record.extraAmounts?.total) : '',
			record.advanceAmounts?.total ? String(record.advanceAmounts?.total) : '',
			record.recoveredAdvanceAmounts?.total ? String(record.recoveredAdvanceAmounts?.total) : '',
			record.scaffoldingAmounts?.total ? String(record.scaffoldingAmounts?.total) : '',
			record.malwaAmounts?.total ? String(record.malwaAmounts?.total) : '',
		] : [
			record.site ? record.site.name : 'N/A',
			getFormattedDateMonth(record.createdAt),
			record.pendingBillAmounts?.week ? String(record.pendingBillAmounts?.week) : '',
			record.billAmounts?.week ? String(record.billAmounts?.week) : '',
			record.penaltyAmounts?.week ? String(record.penaltyAmounts?.week) : '',
			record.debitAmounts?.week ? String(record.debitAmounts?.week) : '',
			record.extraAmounts?.week ? String(record.extraAmounts?.week) : '',
			record.advanceAmounts?.week ? String(record.advanceAmounts?.week) : '',
			record.recoveredAdvanceAmounts?.week ? String(record.recoveredAdvanceAmounts?.week) : '',
			record.scaffoldingAmounts?.week ? String(record.scaffoldingAmounts?.week) : '',
			record.malwaAmounts?.week ? String(record.malwaAmounts?.week) : '',
		]);
	});

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row>
				<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
					<Space>
						<DatePicker
							picker='week'
							format={'YYYY-w'}
							onChange={(date, dateString) => {
								onWeakChange(dateString);
							}}
						/>
						<CSVLink
							data={csvData}
							filename={'Work_Order_Bill_Report.csv'}
						>
							<Button type={'primary'}>
								Download
							</Button>
						</CSVLink>
						<a
							href={getS3Url(reportType === 'ttd'
								? pdfReportFile.totalWorkOrderBill : pdfReportFile.weeklyWorkOrderBill)}
							target='_blank' rel='noreferrer'
						>
							<Button type={'primary'}>
								Open PDF Report
							</Button>
						</a>
					</Space>
					<br/>
				</Col>
			</Row>

			<Row>
				<Col span={24} className='mb-10'>
					<Table
						bordered={true}
						size={'small'}
						dataSource={dataSource}
						scroll={{x: 1400, y: 500}}
						pagination={false}
					>
						<Column
							title='Site'
							dataIndex='site'
							key='site'
							width={240}
							render={(site: Site | undefined) =>
								site ? (site.id ? site.name : <b>{site.name}</b>) : ' - '}
						/>
						<ColumnGroup
							title='*All Pending Bills Amounts'
							key='pendingBillAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='pendingBillAmounts'
									key='pendingBillAmountsTotal'
									render={(pendingBillAmounts: ReportCounts | undefined) =>
										getCurrencyString(pendingBillAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='pendingBillAmounts'
									key='pendingBillAmountsWeek'
									render={(pendingBillAmounts: ReportCounts | undefined) =>
										getCurrencyString(pendingBillAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='^Approved Bills With Work Order Amounts'
							key='billAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='billAmounts'
									key='billAmountsTotal'
									render={(billAmounts: ReportCounts | undefined) =>
										getCurrencyString(billAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='billAmounts'
									key='billAmountsWeek'
									render={(billAmounts: ReportCounts | undefined) =>
										getCurrencyString(billAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Penalty Amounts of All Approved Bills'
							key='penaltyAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='penaltyAmounts'
									key='penaltyAmountsTotal'
									render={(penaltyAmounts: ReportCounts | undefined) =>
										getCurrencyString(penaltyAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='penaltyAmounts'
									key='penaltyAmountsWeek'
									render={(penaltyAmounts: ReportCounts | undefined) =>
										getCurrencyString(penaltyAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Debit Amounts of All Approved Bills'
							key='debitAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='debitAmounts'
									key='debitAmountsTotal'
									render={(debitAmounts: ReportCounts | undefined) =>
										getCurrencyString(debitAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='debitAmounts'
									key='debitAmountsWeek'
									render={(debitAmounts: ReportCounts | undefined) =>
										getCurrencyString(debitAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Approved Bill Without Work Order Amounts'
							key='extraAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='extraAmounts'
									key='extraAmountsTotal'
									render={(extraAmounts: ReportCounts | undefined) =>
										getCurrencyString(extraAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='extraAmounts'
									key='extraAmountsWeek'
									render={(extraAmounts: ReportCounts | undefined) =>
										getCurrencyString(extraAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Approved Advance Bill Amounts'
							key='advanceAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='advanceAmounts'
									key='advanceAmountsTotal'
									render={(advanceAmounts: ReportCounts | undefined) =>
										getCurrencyString(advanceAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='advanceAmounts'
									key='advanceAmountsWeek'
									render={(advanceAmounts: ReportCounts | undefined) =>
										getCurrencyString(advanceAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Recovered Approved Advance Bill Amounts'
							key='recoveredAdvanceAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='recoveredAdvanceAmounts'
									key='recoveredAdvanceAmountsTotal'
									render={(recoveredAdvanceAmounts: ReportCounts | undefined) =>
										getCurrencyString(recoveredAdvanceAmounts?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='recoveredAdvanceAmounts'
									key='recoveredAdvanceAmountsWeek'
									render={(recoveredAdvanceAmounts: ReportCounts | undefined) =>
										getCurrencyString(recoveredAdvanceAmounts?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Approved Scaffolding Bill Amounts'
							key='scaffoldingAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='scaffoldingAmounts'
									key='scaffoldingAmountsTotal'
									render={(amt: ReportCounts | undefined) =>
										getCurrencyString(amt?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='scaffoldingAmounts'
									key='scaffoldingAmountsWeek'
									render={(amt: ReportCounts | undefined) =>
										getCurrencyString(amt?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
						<ColumnGroup
							title='Approved Malwa Bill Amounts'
							key='malwaAmounts'
						>
							{reportType === 'ttd' ? (
								<Column
									title={inTotal}
									dataIndex='malwaAmounts'
									key='malwaAmountsTotal'
									render={(amt: ReportCounts | undefined) =>
										getCurrencyString(amt?.total, false) ?? ''}
								/>
							) : (
								<Column
									title={inWeek}
									dataIndex='malwaAmounts'
									key='malwaAmountsWeek'
									render={(amt: ReportCounts | undefined) =>
										getCurrencyString(amt?.week, false) ?? ''}
								/>
							)}
						</ColumnGroup>
					</Table>
				</Col>
			</Row>

			<Row>
				<Col span={24} className='mb-10'>
					<Card size='small' title={'Important note'}>
						<p><b>*Pending Bills: </b> Bills (all bills) those are not yet re approved</p>
						<p><b>^Approved Bills: </b> Bill (with work order) those are re approved or paid</p>
					</Card>
					<br/>
				</Col>
			</Row>
		</Spin>
	);
};

