import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	Button,
	Checkbox,
	Col,
	Descriptions,
	Empty,
	Form,
	Input,
	message,
	Modal,
	Row, Select,
	Space,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {addDebit, addPenalty, approve, getById, paid, reject, update} from './actions';
import {useHistory, useParams} from 'react-router';
import {
	equalNum,
	formatDateFunction,
	getCurrencyString,
	getHrefLink,
	getPermissionSites,
	getS3Url,
	parseNum,
	toTitleCase,
} from 'app/helpers';
import {
	CheckOutlined,
	CloseOutlined,
	CreditCardOutlined,
	DollarOutlined,
	PaperClipOutlined,
	CheckCircleFilled,
	QuestionCircleFilled,
} from '@ant-design/icons';
import {
	billTypeLabels, ModuleName,
	ModulePermission,
	type PaymentMilestone,
	type Penalty, QueryParentType,
	type Site,
	type WorkOrderBill,
	WorkOrderBillStatus,
	WorkOrderBillType,
	type WorkOrderDebit,
} from 'app/models';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {ViewChildQuery} from 'app/components/Query/ViewChildQuery';
import {getContractors} from '../Common/summary-actions';

type ParamType = {
	id?: string;
};

// eslint-disable-next-line complexity
export const ViewWorkOrderBillDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {loading, byIds, dataUpdated} = useSelector((state: Types.RootState) => state.workOrderBill);
	const {user: authUser, contractorOptions} = useSelector((state: Types.RootState) => state.summary);
	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);
	const params = useParams<ParamType>();

	const billId: number = parseInt(params?.id ?? '0', 10);
	const paySites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_BILL_PAY, ModulePermission.WRITE, allSites);
	const filter: any = {
		include: [
			{relation: 'penalties'},
			{relation: 'debits'},
		],
	};

	const [debitBillId, setDebitBillId] = useState<number>(0);
	const [debits, setDebits] = useState<WorkOrderDebit[]>([]);
	const [penaltyBillId, setPenaltyBillId] = useState<number>(0);
	const [contractorId, setContractorId] = useState<number>(0);
	const [showAddContractorModal, setShowAddContractorModal] = useState<boolean>(false);
	const [penaltyReason, setPenaltyReason] = useState<string>('');
	const [penaltyAmount, setPenaltyAmount] = useState<number>(0);
	const [cancelReason, setCancelReason] = useState<string>('');
	const [cancelBillId, setCancelBillId] = useState<number>(0);

	React.useEffect(() => {
		dispatch(getById(billId, filter));
		dispatch(getContractors());
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			dispatch(getById(billId, filter));
		}
	}, [dataUpdated]);

	const handleApproveClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to approve.')) {
			dispatch(approve(id));
		}
	};

	const handlePaidClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to mark paid.')) {
			dispatch(paid(id));
		}
	};

	const handleAddDebitClick = (id: number) => {
		setDebitBillId(id);
	};

	const handleAddDebitOk = () => {
		if (!debits?.length) {
			void message.error('Please select a debit');
			return;
		}

		setDebitBillId(0);
		setDebits([]);
		if (debitBillId) {
			dispatch(addDebit(debitBillId, {debits}));
		}
	};

	const handleAddDebitCancel = () => {
		setDebitBillId(0);
		setDebits([]);
	};

	const handleAddDebit = (debit: WorkOrderDebit) => {
		const items = [...debits];
		items.push(debit);
		setDebits(items);
	};

	const handleRemoveDebit = (i: number) => {
		const items = [...debits];
		items.splice(i, 1);
		setDebits(items);
	};

	const handleAddPenaltyClick = (id: number) => {
		setPenaltyBillId(id);
	};

	const handleAddPenaltyOk = () => {
		if (!penaltyAmount) {
			void message.error('Enter penalty/recovery amount');
			return;
		}

		setPenaltyBillId(0);
		setPenaltyAmount(0);
		setPenaltyReason('');
		if (penaltyBillId) {
			dispatch(addPenalty(penaltyBillId, {amount: parseInt(String(penaltyAmount), 10), description: penaltyReason}));
		}
	};

	const handleAddPenaltyCancel = () => {
		setPenaltyBillId(0);
		setPenaltyAmount(0);
		setPenaltyReason('');
	};

	const handleAddContractorClick = () => {
		setShowAddContractorModal(true);
	};

	const handleAddContractorOk = () => {
		if (!contractorId) {
			void message.error('Please select a contractor');
			return;
		}

		setContractorId(0);
		setShowAddContractorModal(false);
		if (billId) {
			dispatch(update(billId, {contractorId: parseNum(contractorId)}));
		}
	};

	const handleAddContractorCancel = () => {
		setContractorId(0);
		setShowAddContractorModal(false);
	};

	const handleRejectClick = (id: number) => {
		setCancelBillId(id);
	};

	const handleRejectBillOk = () => {
		setCancelBillId(0);
		if (cancelBillId) {
			dispatch(reject(cancelBillId, {cancelReason}));
		}
	};

	const handleRejectBillCancel = () => {
		setCancelBillId(0);
	};

	const bill = byIds[billId];

	if (!bill || !authUser) {
		return (
			<DefaultLayout currentPath={uiPaths.workOrderBillDetail}>
				<Empty />
			</DefaultLayout>
		);
	}

	const canApprove = equalNum(bill?.nextApprovedBy, authUser?.id);
	const canPay = bill.orderBillStatus === WorkOrderBillStatus.APPROVED && paySites.find(s => equalNum(s.id, bill?.siteId));
	const canAddPenalty = canApprove && bill?.workOrderBillType !== WorkOrderBillType.ADVANCE_AMOUNT;
	const canAddWorkOrderDebit = canApprove && bill?.workOrderBillType !== WorkOrderBillType.ADVANCE_AMOUNT;
	const canAddContractor = canApprove && (bill?.workOrderBillType === WorkOrderBillType.MALWA_BILL
		|| bill?.workOrderBillType === WorkOrderBillType.SCAFFOLDING_BILL
		|| bill?.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT) && (!bill?.contractorId || !bill?.debitWorkOrderId);

	return (
		<DefaultLayout currentPath={uiPaths.workOrderBillDetail}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={2} style={{textAlign: 'center'}}>{'Contractor\'s Bill Detail'}</Typography.Title>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
					</Col>
				</Row>

				<Row className='mb-15'>
					<Col span={24}>
						<Descriptions bordered={true} column={1}>
							<Descriptions.Item label='Bill Submitted Time'>{formatDateFunction(bill.createdAt ?? '')}</Descriptions.Item>
							<Descriptions.Item label='Site'>{bill.site?.name ?? ''}</Descriptions.Item>
							<Descriptions.Item label='Bill Type'>
								{bill.workOrderBillType ? billTypeLabels[bill.workOrderBillType] : ''}
							</Descriptions.Item>
							<Descriptions.Item label='Billed to Client'>
								{bill.billedToClient ? 'Yes' : 'No'}
							</Descriptions.Item>
							<Descriptions.Item label='Work Type'>
								{bill.workOrder ? bill.workOrder.projectProcessMaster?.processName : bill.projectProcessMaster?.processName}
							</Descriptions.Item>
							<Descriptions.Item label='Contractor'>
								{bill.workOrder?.contractor?.name ?? bill.contractor?.name}
							</Descriptions.Item>
							{bill.workOrder ? (
								<>
									<Descriptions.Item label='Work Order'>
										<a
											target='_blank'
											href={getHrefLink(uiPaths.workOrderDetail, bill.workOrder.id)} rel='noreferrer'
										>
											{bill.workOrder.title}
										</a>
									</Descriptions.Item>
									<Descriptions.Item label='Work Order Checks'>
										<Space direction={'vertical'}>
											<Space>
												{bill.qualityCheck ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
												{`Is quality checked for the work order "${bill.workOrder.title}"`}
											</Space>
											<Space>
												{bill.workAsPerSchedule ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
												Is work as per the schedule
											</Space>
											<Space>
												{bill.contractorVisitAsPerWO ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
												Did contractor visit the site {bill.workOrder.contractorVisit}
											</Space>
											<Space>
												{bill.foremenAsPerWO ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
												Is his foreman/ site incharge of site as per work order
											</Space>
										</Space>
									</Descriptions.Item>
								</>
							) : []}
							{bill.billDescription ? (
								<Descriptions.Item label='Bill Description'>
									{bill.billDescription}
								</Descriptions.Item>
							) : []}
							{bill.paymentMilestones?.length ? (
								<Descriptions.Item label='Milestones'>
									<BillMilestonesView milestones={bill.paymentMilestones} />
								</Descriptions.Item>
							) : []}
							{bill.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT ? (
								<>
									<Descriptions.Item label='Debited to Contractor'>
										{bill.debitedToContractor ? 'Yes' : 'No'}
									</Descriptions.Item>
									{bill.debitedToContractor ? (
										<>
											<Descriptions.Item label='Debited Work Order'>
												{bill.debitWorkOrder ? (
													<a target='_blank' href={getHrefLink(uiPaths.workOrderDetail, bill.debitWorkOrder.id)} rel='noreferrer'>
														{bill.debitWorkOrder.title}
													</a>
												) : []}
											</Descriptions.Item>
											<Descriptions.Item label='Contractor has been Informed'>
												{bill.contractorInformed ? 'Yes' : 'No'}
											</Descriptions.Item>
											{bill.contractorInformed ? (
												<Descriptions.Item label='Contractor Communication Attachments'>
													<Space>
														{bill.contractorInformedFiles?.split(',').length
															? bill.contractorInformedFiles.split(',').map((fileKey: string, ixx: number) => (
																<a key={ixx} href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
																	<PaperClipOutlined />
																</a>
															)) : []}
													</Space>
												</Descriptions.Item>
											) : (
												<Descriptions.Item label='Contractor not Informed Reason'>
													{bill?.contractorNotInformedReason ?? ''}
												</Descriptions.Item>
											)}
										</>
									) : (
										<Descriptions.Item label='Not Debiting to Contractor Reason'>
											{bill?.notDebitedToContractorReason ?? ''}
										</Descriptions.Item>
									)}
								</>
							) : []}
							<Descriptions.Item label='Bill Amount'>
								<BillAmountView bill={bill} />
							</Descriptions.Item>
							{!bill.penalties?.length || bill.penalties?.every(penalty => penalty.description !== 'Plaster Debit Amount') ? (
								<Descriptions.Item label='Plaster Debit Amount'>
									No Plaster Debit
								</Descriptions.Item>
							) : []}
							{bill.penalties?.length ? (
								<Descriptions.Item label='Penalties'>
									<BillPenaltiesView penalties={bill.penalties} />
								</Descriptions.Item>
							) : []}
							{bill.debits?.length ? (
								<Descriptions.Item label='Debits'>
									<BillDebitsView debits={bill.debits} />
								</Descriptions.Item>
							) : []}
							<Descriptions.Item label='Bill Attachments'>
								<BillAttachmentView attachments={bill.attachments} />
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>

				<Row style={{marginBottom: 20}}>
					<Col span={24} className='mt-15' style={{textAlign: 'right'}}>
						<Space>
							{canApprove ? (
								<Button type={'primary'} onClick={() => {
									handleApproveClick(bill.id);
								}}>
									<CheckOutlined /> Approve
								</Button>
							) : []}
							{canAddPenalty ? (
								<Button type={'primary'} onClick={() => {
									handleAddPenaltyClick(bill.id);
								}}>
									<DollarOutlined /> Add Penalty/Recovery Amount
								</Button>
							) : []}
							{canAddWorkOrderDebit ? (
								<Button type={'primary'} onClick={() => {
									handleAddDebitClick(bill.id);
								}}>
									<CreditCardOutlined /> Add Work Order Debits
								</Button>
							) : []}
							{canAddContractor ? (
								<Button type={'primary'} onClick={() => {
									handleAddContractorClick();
								}}>
									Add Contractor
								</Button>
							) : []}
							{canPay ? (
								<Button type={'primary'} onClick={() => {
									handlePaidClick(bill.id);
								}}>
									<CreditCardOutlined /> Pay
								</Button>
							) : []}
							{canApprove ? (
								<Button type={'primary'} danger={true} onClick={() => {
									handleRejectClick(bill.id);
								}}>
									<CloseOutlined /> Cancel
								</Button>
							) : []}
						</Space>
					</Col>
				</Row>

				{bill?.siteId ? (
					<ViewChildQuery
						parentType={QueryParentType.WORK_ORDER_BILL}
						parentId={String(billId)}
						siteId={bill.siteId}
						module={ModuleName.WORK_ORDER_BILL}
					/>
				) : []}

				<Modal
					title='Cancel Bill'
					open={Boolean(cancelBillId)}
					onOk={handleRejectBillOk}
					onCancel={handleRejectBillCancel}
				>
					<Space direction={'vertical'}>
						<label>Please prove a reason to cancel this bill</label>
						<Input.TextArea
							placeholder={'Enter Cancel Reason'}
							defaultValue={cancelReason ?? ''}
							value={cancelReason ?? ''}
							onChange={(e: any) => {
								setCancelReason(String(e.target.value));
							}}
						/>
					</Space>
				</Modal>

				<Modal
					title='Add Penalty/Recovery Amount'
					open={Boolean(penaltyBillId)}
					onOk={handleAddPenaltyOk}
					onCancel={handleAddPenaltyCancel}
				>
					<Form layout='vertical'>
						<Form.Item label='Please enter penalty/recovery amount'>
							<Input
								type={'number'}
								placeholder={'Enter Amount'}
								defaultValue={penaltyAmount ?? ''}
								value={penaltyAmount ?? ''}
								onChange={(e: any) => {
									setPenaltyAmount(parseNum(String(e.target.value)));
								}}
							/>
						</Form.Item>
						<Form.Item label='Please provide a reason'>
							<Input.TextArea
								placeholder={'Enter Reason'}
								defaultValue={penaltyReason ?? ''}
								value={penaltyReason ?? ''}
								onChange={(e: any) => {
									setPenaltyReason(String(e.target.value));
								}}
							/>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
					title='Add Contractor'
					open={Boolean(showAddContractorModal)}
					onOk={handleAddContractorOk}
					onCancel={handleAddContractorCancel}
				>
					<Form layout='vertical'>
						<Form.Item label='Please select a contractor'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select a Contractor'
								optionFilterProp='children'
								onChange={(value: string) => {
									setContractorId(parseNum(value));
								}}
								// C filterOption={(input, option) => String(option?.label).includes(input)}
							>
								{contractorOptions?.map((option: FilterOptionItem, ix: number) =>
									<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
								)}
							</Select>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
					title='Add Debits Amount'
					open={Boolean(debitBillId)}
					onOk={handleAddDebitOk}
					onCancel={handleAddDebitCancel}
				>
					<Space direction={'vertical'}>
						{byIds[debitBillId ?? 0]?.workOrder?.debits?.length
							? byIds[debitBillId ?? 0]?.workOrder?.debits?.map((debit: WorkOrderDebit, i: number) => (
								<Checkbox
									key={debit.id}
									disabled={Boolean(debit.workOrderBillId) || (Boolean(debit.validFrom) && new Date(String(debit.validFrom)) >= new Date())}
									onChange={e => {
										if (e.target.checked) {
											handleAddDebit(debit);
										} else {
											handleRemoveDebit(i);
										}
									}}
								>
									{debit.workOrderBillId ? (
										<s>{`(Recovered in Bill) ${debit.description}: ${getCurrencyString(debit.amount)}`}</s>
									) : `${debit.validFrom ? `(Valid from ${formatDateFunction(debit.validFrom, false)})` : ''}
										${debit.description}: ${getCurrencyString(debit.amount)}`}
								</Checkbox>
							)) : (
								<p>Nothing to debits for the work order of this bill.</p>
							)}
					</Space>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};

export const BillAttachmentView: React.FC<{attachments: string}> = ({attachments}) => (
	<>
		{attachments && attachments.split(',').length > 0
			? attachments.split(',').map((fileKey: string, ixx: number) => (
				<p key={ixx}>
					<a href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
						<PaperClipOutlined />
					</a>
				</p>
			)) : []}
	</>
);

export const BillPenaltiesView: React.FC<{penalties: Penalty[]}> = ({penalties}) => (
	<table className={'pure-table pure-table-bordered'}>
		{penalties.map((penalty: Penalty) => (
			<tr key={penalty.id}>
				<td>{penalty.description}</td>
				<td>{toTitleCase(penalty.createdUser?.roles, '_')}</td>
				<td>{getCurrencyString(penalty.amount, (penalty.amount < 0))}</td>
			</tr>
		))}
	</table>
);

export const BillDebitsView: React.FC<{debits: WorkOrderDebit[]}> = ({debits}) => (
	<table className={'pure-table pure-table-bordered'}>
		{debits.map((debit: WorkOrderDebit) => (
			<tr key={debit.id}>
				<td>{debit.description}</td>
				<td>{toTitleCase(debit.addedByUser?.roles, '_')}</td>
				<td>{getCurrencyString(debit.amount, (debit.amount < 0))}</td>
				<td>{formatDateFunction(debit.addedAt, false)}</td>
			</tr>
		))}
	</table>
);

export const BillMilestonesView: React.FC<{milestones: PaymentMilestone[]}> = ({milestones}) => (
	<table className={'pure-table pure-table-bordered'}>
		{milestones.map((milestone: PaymentMilestone) => (
			<tr key={milestone.id}>
				<td>{milestone.sequence}</td>
				<td>{milestone.name}</td>
				<td>{getCurrencyString(milestone.amount)}</td>
			</tr>
		))}
	</table>
);

export const BillAmountView: React.FC<{bill: WorkOrderBill}> = ({bill}) => {
	const penaltyAmount = bill.penalties?.length
		? bill.penalties.reduce((amt, penalty) => amt + penalty.amount, 0)
		: 0;
	const debitAmount = bill.debits?.length
		? bill.debits.reduce((amt, debit) => amt + debit.amount, 0)
		: 0;
	if (penaltyAmount + debitAmount) {
		return (
			<div>
				<p><s>{getCurrencyString(bill.billAmount)}</s></p>
				<p>{getCurrencyString(bill.billAmount - penaltyAmount - debitAmount)}</p>
			</div>
		);
	}

	return <p>{getCurrencyString(bill.billAmount)}</p>;
};
