import React from 'react';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useSelector, useDispatch} from 'react-redux';
import type Types from 'MyTypes';
import {
	Button,
	Col,
	Form,
	message,
	Modal,
	Row,
	Space,
	Spin,
	Typography,
	Select,
	DatePicker,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	complete,
	forceUpdate,
	getById,
	start,
	updateProgressPhotos,
} from './actions';
import {useHistory, useParams} from 'react-router';
import {
	checkFileErr,
	equalNum,
	formatDateFunction,
	getPermissionSites,
	parseNum,
} from 'app/helpers';
import {type Attachment, ModuleName, ModulePermission, QueryParentType} from 'app/models';
import {type UploadFile} from 'app/models/upload-file';
import {FileUpload} from 'app/components/Common/FileUpload';
import {ProjectScheduleStatus} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {ViewChildQuery} from 'app/components/Query/ViewChildQuery';
import {ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {ViewParseLink} from 'app/components/Common/ViewParseLink';
import {
	type ProcessChecklist,
	ProcessChecklistOnActionType,
} from 'app/models/process-checklist.model';
import {ProcessChecklistBlock} from 'app/components/ProjectProcessSchedule/ProcessChecklistBlock';
import {ProcessChecklistResponseStatus} from 'app/models/process-checklist-response.model';
import dayjs from 'dayjs';
import {AttachmentsPreviewBlock} from 'app/components/ProjectProcessSchedule/AttachmentsPreviewBlock';

type MyParams = {
	id?: string;
};

// eslint-disable-next-line complexity
export const ViewProjectProcessScheduleForm: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams<MyParams>();
	const id = parseNum(params?.id ?? '0');

	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {byIds, loading, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.projectProcessSchedule,
	);
	const {allSites, byModule: permissions} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const schedule = id ? byIds[id] : undefined;
	const [startedPhotosList, setStartedPhotosList] = React.useState<UploadFile[]>([]);
	const [inProgressPhotosList, setInProgressPhotosList] = React.useState<UploadFile[]>([]);
	const [completedPhotosList, setCompletedPhotosList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});
	const [showStartModal, setShowStartModal] = React.useState<boolean>(false);
	const [showCompleteModal, setShowCompleteModal]
		= React.useState<boolean>(false);
	const [showForceEditModal, setShowForceEditModal]
		= React.useState<boolean>(false);
	const [forceEditStatus, setForceEditStatus] = React.useState<string>('');
	const [forceEditStartedAt, setForceEditStartedAt]
		= React.useState<string>('');
	const [forceEditCompletedAt, setForceEditCompletedAt]
		= React.useState<string>('');

	React.useEffect(() => {
		console.log('ViewProjectProcessScheduleForm.componentDidMount');
		dispatch(getById(id));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Updated Successfully.');
			if (id) {
				dispatch(getById(id));
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const categorizeChecklist = () => {
		const afterCompletionFilledChecklists: ProcessChecklist[] = [];
		const beforeStartFilledChecklists: ProcessChecklist[] = [];
		const beforeStartUnfilledChecklists: ProcessChecklist[] = [];
		const inProgressFilledChecklists: ProcessChecklist[] = [];
		const inProgressUnfilledChecklists: ProcessChecklist[] = [];
		const afterCompletionUnfilledChecklists: ProcessChecklist[] = [];

		schedule?.projectProcessMaster?.processChecklists?.forEach(checklist => {
			if (
				checklist.onActionType === ProcessChecklistOnActionType.BEFORE_START
			) {
				const checklistResponse = schedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				if (checklistResponse?.status) {
					if (
						checklistResponse?.status
							=== ProcessChecklistResponseStatus.APPROVED
							|| checklistResponse?.status
							=== ProcessChecklistResponseStatus.REJECTED
							|| checklistResponse?.status
							=== ProcessChecklistResponseStatus.SUBMITTED
					) {
						beforeStartFilledChecklists.push(checklist);
					} else {
						beforeStartUnfilledChecklists.push(checklist);
					}
				} else {
					beforeStartUnfilledChecklists.push(checklist);
				}
			}

			if (
				checklist.onActionType === ProcessChecklistOnActionType.IN_PROGRESS
			) {
				const checklistResponse = schedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				if (checklistResponse?.status) {
					if (
						checklistResponse?.status
							=== ProcessChecklistResponseStatus.APPROVED
							|| checklistResponse?.status
							=== ProcessChecklistResponseStatus.REJECTED
							|| checklistResponse?.status
							=== ProcessChecklistResponseStatus.SUBMITTED
					) {
						inProgressFilledChecklists.push(checklist);
					} else {
						inProgressUnfilledChecklists.push(checklist);
					}
				} else {
					inProgressUnfilledChecklists.push(checklist);
				}
			}

			if (
				checklist.onActionType
					=== ProcessChecklistOnActionType.AFTER_COMPLETION
			) {
				const checklistResponse = schedule?.checklistResponses?.find(
					res => res.processChecklistId === checklist.id,
				);
				if (checklistResponse?.status) {
					if (
						checklistResponse?.status
							=== ProcessChecklistResponseStatus.APPROVED
							|| checklistResponse?.status
							=== ProcessChecklistResponseStatus.REJECTED
							|| checklistResponse?.status
							=== ProcessChecklistResponseStatus.SUBMITTED
					) {
						afterCompletionFilledChecklists.push(checklist);
					} else {
						afterCompletionUnfilledChecklists.push(checklist);
					}
				} else {
					afterCompletionUnfilledChecklists.push(checklist);
				}
			}
		});
		return {
			beforeStartFilledChecklists,
			beforeStartUnfilledChecklists,
			inProgressFilledChecklists,
			inProgressUnfilledChecklists,
			afterCompletionFilledChecklists,
			afterCompletionUnfilledChecklists,
		};
	};

	const checklistCategories = categorizeChecklist();

	const updateModalState = () => {
		if (schedule?.projectProcessMaster?.processChecklists?.length) {
			if (schedule?.status === ProjectScheduleStatus.STARTED) {
				const noFilledChecklist = [
					...checklistCategories.inProgressUnfilledChecklists,
					...checklistCategories.afterCompletionUnfilledChecklists,
				];
				if (noFilledChecklist.length) {
					void message.error(
						`There are ${noFilledChecklist.length} unfilled checklists.`,
					);
					return;
				}

				const notApprovedChecklists = [
					...checklistCategories.inProgressFilledChecklists,
					...checklistCategories.afterCompletionFilledChecklists,
				].filter(checklist => {
					const checklistResponse = schedule?.checklistResponses?.find(
						res => res.processChecklistId === checklist.id,
					);
					return (
						!checklistResponse?.status
						|| checklistResponse?.status
						!== ProcessChecklistResponseStatus.APPROVED
					);
				});
				if (notApprovedChecklists?.length) {
					void message.error(
						`There are ${notApprovedChecklists?.length} not approved checklists.`,
					);
					return;
				}
			} else {
				if (checklistCategories.beforeStartUnfilledChecklists.length) {
					void message.error(
						`There are ${checklistCategories.beforeStartUnfilledChecklists.length} unfilled checklists.`,
					);
					return;
				}

				const notApprovedChecklists
					= checklistCategories.beforeStartFilledChecklists.filter(
						checklist => {
							const checklistResponse = schedule?.checklistResponses?.find(
								res => res.processChecklistId === checklist.id,
							);
							return (
								!checklistResponse?.status
								|| checklistResponse?.status
								!== ProcessChecklistResponseStatus.APPROVED
							);
						},
					);
				if (notApprovedChecklists?.length) {
					void message.error(
						`There are ${notApprovedChecklists?.length} not approved checklists.`,
					);
					return;
				}
			}
		}

		if (schedule?.status === ProjectScheduleStatus.STARTED) {
			setShowCompleteModal(true);
		} else {
			setShowStartModal(true);
		}
	};

	const onStartClicked = () => {
		if (!schedule) {
			void message.error('Something went wrong. Please go back and try again.');
			return;
		}

		checkFileErr(startedPhotosList);
		const startedPhotos = startedPhotosList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));
		if (!startedPhotos?.length) {
			void message.error('Please Upload Photos');
			return;
		}

		dispatch(
			start(id, {
				status: ProjectScheduleStatus.STARTED,
				startedPhotos,
				schedule,
			}),
		);
		setShowStartModal(false);
	};

	const onCompleteClicked = () => {
		if (!schedule) {
			void message.error('Something went wrong. Please go back and try again.');
			return;
		}

		checkFileErr(completedPhotosList);

		const completedPhotos = completedPhotosList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));

		dispatch(
			complete(id, {
				status: ProjectScheduleStatus.COMPLETED,
				completedPhotos,
				schedule,
			}),
		);
		setShowCompleteModal(false);
	};

	const onForceEditClicked = () => {
		setShowForceEditModal(true);
	};

	const onForceEditSubmit = () => {
		if (
			// eslint-disable-next-line no-alert
			!window.confirm(
				'Please make sure forcefully editing might create some repercussion. Are you sure you want to force edit this activity?',
			)
		) {
			return;
		}

		if (
			forceEditStatus === String(ProjectScheduleStatus.STARTED)
			&& !forceEditStartedAt
		) {
			void message.error(
				'You can not set status to start when started date is not set.',
			);
			return;
		}

		if (
			forceEditStatus === String(ProjectScheduleStatus.COMPLETED)
			&& (!forceEditStartedAt || !forceEditCompletedAt)
		) {
			void message.error(
				'You can not set status to completed when started date and completed date is not set.',
			);
			return;
		}

		const data = {
			status: forceEditStatus,
			startedAt: forceEditStartedAt ? new Date(forceEditStartedAt) : undefined,
			completedAt: forceEditCompletedAt
				? new Date(forceEditCompletedAt)
				: undefined,
		};
		dispatch(forceUpdate(id, data));
		setShowForceEditModal(false);
	};

	const onForceEditCancel = () => {
		setShowForceEditModal(false);
	};

	const saveInProgressPhotographs = () => {
		if (inProgressPhotosList.length === 0) {
			void message.error('Please upload In progress photos');
			return;
		}

		checkFileErr(inProgressPhotosList);
		const inProgressPhotos = inProgressPhotosList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));
		dispatch(updateProgressPhotos(id, {inProgressPhotos}));
	};

	const saveAfterCompletionPhotographs = () => {
		if (completedPhotosList.length === 0) {
			void message.error('Please upload After Completion photos');
			return;
		}

		checkFileErr(completedPhotosList);
		const completedPhotos = completedPhotosList.map((file: UploadFile) => ({
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
		}));
		// Not passing status and using this just to update completed photos
		dispatch(
			complete(id, {
				completedPhotos,
			}),
		);
		setShowCompleteModal(false);
	};

	const moduleName
		= schedule?.projectProcessType === ProjectProcessType.APPROVALS
			? ModuleName.APPROVAL_SCHEDULE
			: ModuleName.CONSTRUCTION_SCHEDULE;
	const writeSites = getPermissionSites(
		permissions,
		moduleName,
		ModulePermission.WRITE,
		allSites,
	);
	const canWrite = writeSites.find(({id}) => equalNum(schedule?.siteId, id));
	const canForceEdit = Boolean(authUser?.isAdmin);
	const canComplete
		= canWrite && schedule?.status === ProjectScheduleStatus.STARTED;
	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};
	const mapOfInProgressPhotos: Record<string, Attachment[]> = {};
	const mapOfAfterCompletionPhotos: Record<string, Attachment[]> = {};
	schedule?.inProgressPhotos?.reverse().forEach(item => {
		if (!mapOfInProgressPhotos[formatDateFunction(item.createdAt, false)]) {
			mapOfInProgressPhotos[formatDateFunction(item.createdAt, false)] = [];
		}

		mapOfInProgressPhotos[formatDateFunction(item.createdAt, false)].push(item);
	});
	schedule?.completedPhotos?.reverse().forEach(item => {
		if (!mapOfAfterCompletionPhotos[formatDateFunction(item.createdAt, false)]) {
			mapOfAfterCompletionPhotos[formatDateFunction(item.createdAt, false)] = [];
		}

		mapOfAfterCompletionPhotos[formatDateFunction(item.createdAt, false)].push(item);
	});

	return (
		<DefaultLayout currentPath={uiPaths.createProjectProcessSchedule}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row className='mb-15'>
					<Col span={24}>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
							{schedule?.projectProcessMaster?.processName ?? ''}
						</Typography.Title>
						<Typography.Title level={5} style={{textAlign: 'center'}}>
							{schedule?.site?.name}
						</Typography.Title>
						<Form {...layout} name='basic'>
							<Form.Item label={<>Planned Start Date</>}>
								<b>{formatDateFunction(schedule?.startDate, false)}</b>
							</Form.Item>
							{schedule?.startedAt ? (
								<Form.Item label={<>Actual Start Date</>}>
									<p>
										<b>{formatDateFunction(schedule?.startedAt, false)}</b>
									</p>
									{schedule?.startedPhotos?.length ? (
										<AttachmentsPreviewBlock
											attachments={schedule.startedPhotos}
										/>
									) : (
										[]
									)}
								</Form.Item>
							) : (
								[]
							)}
							<Form.Item label={<>Planned Completion Date</>}>
								<b>{formatDateFunction(schedule?.endDate, false)}</b>
							</Form.Item>
							{schedule?.completedAt ? (
								<Form.Item label={<>Actual Completion Date</>}>
									<p>
										<b>{formatDateFunction(schedule?.completedAt, false)}</b>
									</p>
									{schedule?.completedPhotos?.length ? (
										<AttachmentsPreviewBlock
											attachments={schedule.completedPhotos}
										/>
									) : (
										[]
									)}
								</Form.Item>
							) : (
								[]
							)}
							<Form.Item label={<>Current Status</>}>
								{schedule?.status ? (
									<>
										{schedule.status === String(ProjectScheduleStatus.STARTED) ? (
											<Space>
												<b>In Progress</b>
												{canWrite ? (
													<Button type='primary' onClick={updateModalState}>
													Change Status
													</Button>
												) : (
													[]
												)}
											</Space>
										) : (
											[]
										)}
										{schedule.status === String(ProjectScheduleStatus.COMPLETED) ? (
											<b>Completed</b>
										) : (
											[]
										)}
									</>
								) : (
									<Space>
										<b>Yet to Start</b>
										{canWrite ? (
											<Button type='primary' onClick={updateModalState}>
												Change Status
											</Button>
										) : (
											[]
										)}
									</Space>
								)}
							</Form.Item>
							{schedule?.status ? (
								<Form.Item label='In Progress Photographs'>
									{schedule.status === String(ProjectScheduleStatus.STARTED)
										&& canWrite ? (
											<>
												<FileUpload
													label={'Click to Upload Photographs'}
													prefix={'project-schedule'}
													fileList={inProgressPhotosList}
													filePrefixKeys={filePrefixKeys}
													onFileChange={(
														inProgressPhotosList,
														filePrefixKeys,
													) => {
														setInProgressPhotosList(inProgressPhotosList);
														setFilePrefixKeys(filePrefixKeys);
													}}
												/>
												<br />
												<Form.Item>
													<Button
														type='primary'
														onClick={saveInProgressPhotographs}
													>
													Save In Progress Photographs
													</Button>
												</Form.Item>
											</>
										) : []}
									<Space direction='vertical'>
										{Object.entries(mapOfInProgressPhotos).map(
											([date, items], ix) => (
												<Space key={ix} direction='vertical'>
													<b>{date}</b>
													{items?.length ? (
														<AttachmentsPreviewBlock
															attachments={items}
														/>
													) : []}
												</Space>
											),
										)}
									</Space>
								</Form.Item>
							) : (
								[]
							)}
							{schedule?.status ? (
								<>
									{schedule?.status === String(ProjectScheduleStatus.STARTED) ? (
										<>
											{checklistCategories.beforeStartFilledChecklists.length
												|| checklistCategories.inProgressFilledChecklists.length
												|| checklistCategories.afterCompletionFilledChecklists
													.length ? (
													<Form.Item label={'Filled Checklists'}>
														{[
															...checklistCategories.beforeStartFilledChecklists,
															...checklistCategories.inProgressFilledChecklists,
															...checklistCategories.afterCompletionFilledChecklists,
														].map((checklist, ix) => (
															<ProcessChecklistBlock
																key={ix}
																checklist={checklist}
																checklistResponse={schedule?.checklistResponses?.find(
																	res => res.processChecklistId === checklist.id,
																)}
																scheduleId={schedule.id}
																siteId={schedule.siteId}
																projectProcessType={schedule.projectProcessType}
															/>
														))}
													</Form.Item>
												) : (
													[]
												)}
											{checklistCategories.afterCompletionUnfilledChecklists
												.length
												|| checklistCategories.inProgressUnfilledChecklists
													.length ? (
													<Form.Item
														label={
															<>
																{'Checklist to be Filled Before'}
																<br />
																{' You Change the Status'}
															</>
														}
													>
														{[
															...checklistCategories.inProgressUnfilledChecklists,
															...checklistCategories.afterCompletionUnfilledChecklists,
														].map((checklist, ix) => (
															<ProcessChecklistBlock
																key={ix}
																checklist={checklist}
																checklistResponse={schedule?.checklistResponses?.find(
																	res => res.processChecklistId === checklist.id,
																)}
																scheduleId={schedule.id}
																siteId={schedule.siteId}
																projectProcessType={schedule.projectProcessType}
															/>
														))}
													</Form.Item>
												) : (
													[]
												)}
										</>
									) : []}
									{schedule?.status === String(ProjectScheduleStatus.COMPLETED) ? (
										<>
											{checklistCategories.beforeStartFilledChecklists.length
												|| checklistCategories.inProgressFilledChecklists.length
												|| checklistCategories.afterCompletionFilledChecklists
													.length ? (
													<Form.Item label={'Filled Checklists'}>
														{[
															...checklistCategories.beforeStartFilledChecklists,
															...checklistCategories.inProgressFilledChecklists,
															...checklistCategories.afterCompletionFilledChecklists,
														].map((checklist, ix) => (
															<ProcessChecklistBlock
																key={ix}
																checklist={checklist}
																checklistResponse={schedule?.checklistResponses?.find(
																	res => res.processChecklistId === checklist.id,
																)}
																scheduleId={schedule.id}
																siteId={schedule.siteId}
																projectProcessType={schedule.projectProcessType}
															/>
														))}
													</Form.Item>
												) : (
													[]
												)}
										</>
									) : []}
								</>
							) : (
								<>
									{checklistCategories.beforeStartFilledChecklists.length
										&& schedule ? (
											<Form.Item label={'Filled Checklists'}>
												{checklistCategories.beforeStartFilledChecklists.map(
													(checklist, ix) => (
														<ProcessChecklistBlock
															key={ix}
															checklist={checklist}
															checklistResponse={schedule?.checklistResponses?.find(
																res => res.processChecklistId === checklist.id,
															)}
															scheduleId={schedule?.id}
															siteId={schedule?.siteId}
															projectProcessType={schedule.projectProcessType}
														/>
													),
												)}
											</Form.Item>
										) : (
											[]
										)}
									{checklistCategories.beforeStartUnfilledChecklists.length
										&& schedule ? (
											<Form.Item
												label={
													<>
														{'Checklist to be Filled Before'}
														<br />
														{' You Change the Status'}
													</>
												}
											>
												{checklistCategories.beforeStartUnfilledChecklists.map(
													(checklist, ix) => (
														<ProcessChecklistBlock
															key={ix}
															checklist={checklist}
															checklistResponse={schedule?.checklistResponses?.find(
																res => res.processChecklistId === checklist.id,
															)}
															scheduleId={schedule?.id}
															siteId={schedule?.siteId}
															projectProcessType={schedule.projectProcessType}
														/>
													),
												)}
											</Form.Item>
										) : (
											[]
										)}
								</>
							)}
							{canWrite
								&& schedule?.status === ProjectScheduleStatus.STARTED
								&& [
									...checklistCategories.afterCompletionFilledChecklists,
									...checklistCategories.afterCompletionUnfilledChecklists,
								].length > 0 ? (
									<>
										<Form.Item label='After Completion Photographs'>
											<FileUpload
												label={'Click to Upload Photographs'}
												prefix={'project-schedule'}
												fileList={completedPhotosList}
												filePrefixKeys={filePrefixKeys}
												onFileChange={(completedPhotosList, filePrefixKeys) => {
													setCompletedPhotosList(completedPhotosList);
													setFilePrefixKeys(filePrefixKeys);
												}}
											/>
										</Form.Item>
										<Form.Item {...tailLayout}>
											<Button
												type='primary'
												onClick={saveAfterCompletionPhotographs}
											>
											Save After Completion Photographs
											</Button>
											<Space direction='vertical'>
												{Object.entries(mapOfAfterCompletionPhotos).map(
													([date, items], ix) => (
														<Space key={ix} direction='vertical'>
															<b>{date}</b>
															{items?.length ? (
																<AttachmentsPreviewBlock
																	attachments={items}
																/>
															) : []}
														</Space>
													),
												)}
											</Space>
										</Form.Item>
									</>
								) : (
									[]
								)}
							{schedule?.projectProcessMaster?.processDocument ? (
								<Form.Item label={<>{'Process Documents'}</>}>
									<ViewParseLink
										parseLink={schedule.projectProcessMaster.processDocument}
									/>
								</Form.Item>
							) : (
								[]
							)}
							{schedule?.projectProcessMaster?.referenceDocuments ? (
								<Form.Item label={<>{'Reference Documents'}</>}>
									<ViewParseLink
										parseLink={schedule.projectProcessMaster.referenceDocuments}
									/>
								</Form.Item>
							) : (
								[]
							)}
							{/* {canComplete ? (
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    onClick={onSubmitClicked}
                    disabled={
                      schedule?.status === ProjectScheduleStatus.COMPLETED
                    }
                  >
                    Submit
                  </Button>
                </Form.Item>
              ) : (
                []
              )} */}
							{canForceEdit ? (
								<Form.Item {...tailLayout}>
									<Button
										type='primary'
										danger={true}
										onClick={onForceEditClicked}
									>
										Forcefully Edit
									</Button>
								</Form.Item>
							) : (
								[]
							)}
						</Form>
					</Col>
				</Row>
				{schedule?.siteId ? (
					<ViewChildQuery
						// History={history}
						parentType={QueryParentType.PROJECT_SCHEDULE}
						parentId={String(id)}
						siteId={schedule.siteId}
						module={moduleName}
					/>
				) : (
					[]
				)}
				<Modal
					title='Start this Activity'
					visible={showStartModal}
					okText={'Start'}
					onOk={onStartClicked}
					onCancel={() => {
						setShowStartModal(false);
						setStartedPhotosList([]);
					}}
				>
					<Space direction={'vertical'}>
						<label>Please Upload Photos for Starting</label>
						<FileUpload
							label={'Click to Upload Photographs'}
							prefix={'project-schedule'}
							fileList={startedPhotosList}
							filePrefixKeys={filePrefixKeys}
							onFileChange={(startedPhotosList, filePrefixKeys) => {
								setFilePrefixKeys(filePrefixKeys);
								setStartedPhotosList(startedPhotosList);
							}}
						/>
					</Space>
				</Modal>

				<Modal
					title='Complete this Activity'
					visible={showCompleteModal}
					okText={'Complete'}
					onOk={onCompleteClicked}
					onCancel={() => {
						setShowCompleteModal(false);
						setCompletedPhotosList([]);
					}}
				>
					<Space direction={'vertical'}>
						<label>Please Upload Photos for Completion </label>
						<FileUpload
							label={'Click to Upload Photographs'}
							prefix={'project-schedule'}
							fileList={completedPhotosList}
							filePrefixKeys={filePrefixKeys}
							onFileChange={(completedPhotosList, filePrefixKeys) => {
								setFilePrefixKeys(filePrefixKeys);
								setCompletedPhotosList(completedPhotosList);
							}}
						/>
					</Space>
				</Modal>
				<Modal
					title='Forcefully Edit this Activity'
					open={showForceEditModal}
					okText={'Edit'}
					onOk={onForceEditSubmit}
					onCancel={onForceEditCancel}
				>
					<Form>
						<Form.Item label={'Status'}>
							<Select
								style={{width: 240}}
								defaultValue={forceEditStatus ?? ''}
								onChange={value => {
									setForceEditStatus(String(value) as ProjectScheduleStatus);
								}
								}
							>
								<Select.Option value=''>
									Select Status of Activity
								</Select.Option>
								<Select.Option value={ProjectScheduleStatus.STARTED}>
									Started
								</Select.Option>
								<Select.Option value={ProjectScheduleStatus.COMPLETED}>
									Completed
								</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item label='Started Date'>
							<DatePicker
								value={
									forceEditStartedAt ? dayjs(forceEditStartedAt) : undefined
								}
								onChange={(date, dateString) => {
									setForceEditStartedAt(dateString);
								}
								}
							/>
						</Form.Item>
						<Form.Item label='Completed Date'>
							<DatePicker
								value={
									forceEditCompletedAt
										? dayjs(forceEditCompletedAt)
										: undefined
								}
								onChange={(date, dateString) => {
									setForceEditCompletedAt(dateString);
								}
								}
							/>
						</Form.Item>
					</Form>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
