import React from 'react';
import {formatDateFunction, getHrefLink, getS3Url} from 'app/helpers';
import {uiPaths} from 'app/constants';
import {Col, Descriptions, Row, Space} from 'antd';
import {
	billTypeLabels,
	type WorkOrderBill,
	WorkOrderBillType,
} from 'app/models';
import {CheckCircleFilled, PaperClipOutlined, QuestionCircleFilled} from '@ant-design/icons';
import {
	BillAmountView, BillAttachmentView, BillDebitsView,
	BillMilestonesView,
	BillPenaltiesView,
} from 'app/components/WorkOrderBill/ViewWorkOrderBillDetail';

type Props = {
	workOrderBill: WorkOrderBill;
};

// eslint-disable-next-line complexity
export const ViewWorkOrderBillSummary: React.FC<Props> = ({workOrderBill: bill}) => (
	<Row className='mb-15'>
		<Col span={24}>
			<Descriptions bordered={true} column={1}>
				<Descriptions.Item label='Bill Submitted Time'>{formatDateFunction(bill.createdAt ?? '')}</Descriptions.Item>
				<Descriptions.Item label='Site'>{bill.site?.name ?? ''}</Descriptions.Item>
				<Descriptions.Item label='Bill Type'>
					{bill.workOrderBillType ? billTypeLabels[bill.workOrderBillType] : ''}
				</Descriptions.Item>
				<Descriptions.Item label='Work Type'>
					{bill.workOrder ? bill.workOrder.projectProcessMaster?.processName : bill.projectProcessMaster?.processName}
				</Descriptions.Item>
				{bill.workOrder ? (
					<>
						<Descriptions.Item label='Work Order'>
							<a
								target='_blank'
								href={getHrefLink(uiPaths.workOrderDetail, bill.workOrder.id)} rel='noreferrer'
							>
								{bill.workOrder.title}
							</a>
						</Descriptions.Item>
						<Descriptions.Item label='Contractor'>
							{bill.workOrder.contractor?.name ?? bill.contractor?.name}
						</Descriptions.Item>
						<Descriptions.Item label='Work Order Checks'>
							<Space direction={'vertical'}>
								<Space>
									{bill.qualityCheck ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
									{`Is quality checked for the work order "${bill.workOrder.title}"`}
								</Space>
								<Space>
									{bill.workAsPerSchedule ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
									{'Is work as per the schedule'}
								</Space>
								<Space>
									{bill.contractorVisitAsPerWO ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
									{`Did contractor visit the site ${bill.workOrder.contractorVisit}`}
								</Space>
								<Space>
									{bill.foremenAsPerWO ? <CheckCircleFilled style={{color: 'green'}} /> : <QuestionCircleFilled />}
									{'Is his foreman/ site incharge of site as per work order'}
								</Space>
							</Space>
						</Descriptions.Item>
					</>
				) : []}
				{bill.billDescription ? (
					<Descriptions.Item label='Bill Description'>
						{bill.billDescription}
					</Descriptions.Item>
				) : []}
				{bill.paymentMilestones?.length ? (
					<Descriptions.Item label='Milestones'>
						<BillMilestonesView milestones={bill.paymentMilestones} />
					</Descriptions.Item>
				) : []}
				{bill.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT ? (
					<>
						<Descriptions.Item label='Debited to Contractor'>
							{bill.debitedToContractor ? 'Yes' : 'No'}
						</Descriptions.Item>
						{bill.debitedToContractor ? (
							<>
								<Descriptions.Item label='Debited Work Order'>
									{bill.debitWorkOrder ? (
										<a target='_blank' href={getHrefLink(uiPaths.workOrderDetail, bill.debitWorkOrder.id)} rel='noreferrer'>
											{bill.debitWorkOrder.title}
										</a>
									) : []}
								</Descriptions.Item>
								<Descriptions.Item label='Contractor has been Informed'>
									{bill.contractorInformed ? 'Yes' : 'No'}
								</Descriptions.Item>
								{bill.contractorInformed ? (
									<Descriptions.Item label='Contractor Communication Attachments'>
										<Space>
											{bill.contractorInformedFiles?.split(',').length
												? bill.contractorInformedFiles.split(',').map((fileKey: string, ixx: number) => (
													<a key={ixx} href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
														<PaperClipOutlined />
													</a>
												)) : []}
										</Space>
									</Descriptions.Item>
								) : (
									<Descriptions.Item label='Contractor not Informed Reason'>
										{bill?.contractorNotInformedReason ?? ''}
									</Descriptions.Item>
								)}
							</>
						) : (
							<Descriptions.Item label='Not Debiting to Contractor Reason'>
								{bill?.notDebitedToContractorReason ?? ''}
							</Descriptions.Item>
						)}
					</>
				) : [] }
				<Descriptions.Item label='Bill Amount'>
					<BillAmountView bill={bill} />
				</Descriptions.Item>
				{bill.penalties?.length ? (
					<Descriptions.Item label='Penalties'>
						<BillPenaltiesView penalties={bill.penalties} />
					</Descriptions.Item>
				) : []}
				{bill.debits?.length ? (
					<Descriptions.Item label='Debits'>
						<BillDebitsView debits={bill.debits} />
					</Descriptions.Item>
				) : []}
				<Descriptions.Item label='Bill Attachments'>
					<BillAttachmentView attachments={bill.attachments}/>
				</Descriptions.Item>
			</Descriptions>
		</Col>
	</Row>
);
