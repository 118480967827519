import {combineReducers} from 'redux';
import {type History} from 'history';

import {connectRouter} from 'connected-react-router';
import auth from '../components/Common/auth-reducer';
import aws from '../components/Common/aws-reducer';
import summary from '../components/Common/summary-reducer';
import comment from '../components/Common/comment-reducer';
import expenseReport from '../components/ExpenseReport/reducer';
import prithuWallet from '../components/Wallet/reducer';
import query from '../components/Query/reducer';
import clientQuery from '../components/ClientQuery/reducer';
import corporateQuery from '../components/CorporateQuery/reducer';
import user from '../components/User/reducer';
import site from '../components/Site/reducer';
import userPermission from '../components/UserPermission/reducer';
import drawingSchedule from '../components/DrawingSchedule/reducer';
import notification from '../components/Notification/reducer';
import paidExpense from '../components/PaidExpense/reducer';
import materialQuery from '../components/MaterialQuery/reducer';
import workOrder from '../components/WorkOrder/reducer';
import workOrderBill from '../components/WorkOrderBill/reducer';
import paymentDetail from '../components/PaymentDetail/reducer';
import googleScheduleSheet from '../components/GoogleScheduleSheet/reducer';
import weeklyExpenseReport from '../components/WeeklyExpenseReport/reducer';
import workOrderBillReport from '../components/WorkOrderBillReport/reducer';
import workOrderSchedule from '../components/WorkOrderSchedule/reducer';
import weeklyPendingAction from '../components/WeeklyPendingAction/reducer';
import changeLog from '../components/ChangeLog/reducer';
import projectProcessMaster from '../components/ProjectProcessMaster/reducer';
import projectProcessSchedule from '../components/ProjectProcessSchedule/reducer';
import processChecklist from '../components/ProcessChecklist/reducer';
import processChecklistResponse from '../components/ProcessChecklistResponse/reducer';
import approval from '../components/Approval/reducer';
import scheduleDelayReport from '../components/Reports/schedule-delay-report-reducer';
import checklistResponseReport from '../components/Reports/checklist-response-report-reducer';
import projectQueryReport from '../components/Reports/project-query-report-reducer';
import clientQueryReport from '../components/Reports/client-query-report-reducer';
import emailTemplate from 'app/components/EmailTemplate/reducer';
import projectHandoverActivity from 'app/components/ProjectHandoverActivity/reducer';
import materialOrderBudget from 'app/components/ProjectBudgetQuantities/reducer';
import monthlyPettyCashReport from 'app/components/Reports/monthly-petty-cash-report-reducer';
import materialOrderDailyQuantity from 'app/components/MaterialOrderDailyQuantity/reducer';
import scheduleManualEntry from 'app/components/ScheduleManualEntry/reducer';
import clientQueryDeviceUsageReport from 'app/components/Reports/client-query-device-usage-report-reducer';
import checklistApprovalLog from 'app/components/Reports/checklist-approval-report-reducer';
import approvalScheduleActivityDelayReport from 'app/components/Reports/approval-schedule-activity-delay-report-reducer';
import clientQueryOverdueReport from 'app/components/Reports/client-query-overdue-report-reducer';
import labourAttendance from 'app/components/LabourAttendance/reducer';
import corporateQueryReport from 'app/components/Reports/corporate-query-report.reducer';
import activityTemplate from 'app/components/ActivityTemplate/reducer';
import activityTemplateItem from 'app/components/ActivityTemplateItem/reducer';
import projectActivity from 'app/components/ProjectActivity/reducer';
import drawingScheduleActivityReport from 'app/components/Reports/drawing-schedule-activity-report-reducer';
import electricityConsumption from 'app/components/ElectricityConsumption/reducer';
import electricityConsumptionReport from 'app/components/Reports/electricity-consumption-report-reducer';
import category from 'app/components/Category/reducer';
import workOrderQuery from 'app/components/WorkOrderQuery/reducer';
import constructionSchedulePhotoReport from 'app/components/Reports/construction-schedule-photo-report-reducer';

const rootReducer = (history: History) => combineReducers({
	router: connectRouter(history),
	auth,
	aws,
	summary,
	comment,
	expenseReport,
	prithuWallet,
	query,
	clientQuery,
	corporateQuery,
	user,
	site,
	userPermission,
	drawingSchedule,
	notification,
	paidExpense,
	materialQuery,
	workOrder,
	workOrderBill,
	paymentDetail,
	googleScheduleSheet,
	weeklyExpenseReport,
	workOrderBillReport,
	workOrderSchedule,
	weeklyPendingAction,
	changeLog,
	projectProcessMaster,
	projectProcessSchedule,
	processChecklist,
	processChecklistResponse,
	approval,
	scheduleDelayReport,
	projectQueryReport,
	clientQueryReport,
	emailTemplate,
	projectHandoverActivity,
	materialOrderBudget,
	monthlyPettyCashReport,
	materialOrderDailyQuantity,
	scheduleManualEntry,
	checklistResponseReport,
	clientQueryDeviceUsageReport,
	checklistApprovalLog,
	approvalScheduleActivityDelayReport,
	clientQueryOverdueReport,
	labourAttendance,
	corporateQueryReport,
	activityTemplate,
	activityTemplateItem,
	projectActivity,
	drawingScheduleActivityReport,
	electricityConsumption,
	electricityConsumptionReport,
	category,
	workOrderQuery,
	constructionSchedulePhotoReport,
});

export default rootReducer;
