
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {uiPaths} from 'app/constants';
import {getCurrencyString, parseNum, reduceUniqueIds, toTitleCase} from 'app/helpers';
import {FilterView, type FilterDataType} from '../Common/Filter/FilterView';
import {count, get, onPaginationChange, setFilterValue} from '../WorkOrder/actions';
import {Col, Empty, Row, Space, Spin, Table, message} from 'antd';
import {FilterFormType, type FilterOptionItem, type UiFilter} from 'app/models/ui-filter';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {SiteStatus, WorkOrderBillStatus, WorkOrderStatus} from 'app/models';
import {get as getSites} from '../Site/actions';
import {get as getWorkTypes} from '../ProjectProcessMaster/actions';
import {ProjectProcessType} from '../ProjectProcessMaster/project-process-master';

export type DataSourceType = {
	key: string;
	workOrderName: string;
	contractorName: string;
	workType: string;
	workOrderStatus: string;
	totalAmount: number;
	balanceAmount: number;
};

export const ViewWorkOrderBalancePaymentReport: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds, filterValue, totalCount, currentPos, perPageSize} = useSelector(
		(state: Types.RootState) => state.workOrder,
	);
	const {sites} = useSelector(
		(state: Types.RootState) => state.site,
	);
	const {byIds: workTypeByIds, allIds: workTypeAllIds} = useSelector((state: Types.RootState) => state.projectProcessMaster);

	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	const workTypeOptions: FilterOptionItem[] = workTypeAllIds.map(id => ({
		label: workTypeByIds[id]?.processName,
		value: String(id),
	}));

	React.useEffect(() => {
		dispatch(
			getSites({
				where: {
					status: {inq: [
						SiteStatus.UNDER_DESIGN,
						SiteStatus.UNDER_CONSTRUCTION,
						SiteStatus.UNDER_WARRANTY,
						SiteStatus.RECENTLY_COMPLETED,
					]},
				},
			}),
		);
		dispatch(getWorkTypes({
			where: {
				projectProcessType: ProjectProcessType.ORDER,
			},
		}));
	}, []);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		if (!f.siteId) {
			void message.error('Please select a site');
			return;
		}

		const filter: any = {
			where: {
				siteId: parseNum(String(f.siteId)),
				status: {inq: [WorkOrderStatus.PUBLISHED, WorkOrderStatus.COMPLETED]},
			},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
			include: [
				{relation: 'contractorForemen'},
				{relation: 'paymentMilestones'},
				{relation: 'debits'},
			],
		};

		if (f.workTypeId) {
			filter.where.projectProcessMasterId = f.workTypeId;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const dataSource: DataSourceType[] = allIds.map(id => byIds[id]).map(item => {
		const totalMilestoneAmount = item.paymentMilestones?.length ? item.paymentMilestones.reduce((total, milestone) => total + milestone.amount, 0) : 0;
		const milestoneWorkOrderBillIds = item.paymentMilestones?.length ? item.paymentMilestones
			.reduce<number[]>((ids, {workOrderBillId: id}) => reduceUniqueIds<number>(ids, id), []) : [];

		const totalPaidAgainstMilestones = item.paidBills?.length ? item.paidBills
			.filter(paidBill => milestoneWorkOrderBillIds.includes(paidBill.id))
			.reduce((total, paidBill) => {
				total += paidBill.billAmount;

				return total;
			}, 0) : 0;

		const data: DataSourceType = {
			key: '',
			workOrderName: item.title,
			contractorName: item.contractor?.name ?? '',
			workType: item.projectProcessMaster?.processName ?? '',
			workOrderStatus: toTitleCase(String(item.status), '_') ?? '',
			totalAmount: totalMilestoneAmount,
			balanceAmount: totalMilestoneAmount - totalPaidAgainstMilestones, // We need to consider debits and penalties as well
			// TotalAmount: getCurrencyString(totalMilestoneAmount, false),
			// balanceAmount: getCurrencyString(totalMilestoneAmount - totalPaidAgainstMilestones, false),
		};
		return data;
	});

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		},
		{
			filterKey: 'workTypeId',
			items: workTypeOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Type',
			label: 'Work Type',
			defaultValue: filterValue?.workTypeId,
		},
	];

	// Calculate total work order amount and balance amount
	const totalWorkOrderAmount = dataSource.reduce((sum, record) => sum + record.totalAmount, 0);
	const totalBalanceAmount = dataSource.reduce((sum, record) => sum + record.balanceAmount, 0);

	return (
		<DefaultLayout
			currentPath={uiPaths.approvalScheduleActivityDelayReport}
		>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
							filterData(f);
						}} />
						<br />
					</Col>
				</Row>
				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos ?? 1}
								perPageSize={perPageSize ?? 10}
								pageSizeOptions={['10', '50', '100', '500', '1000', '5000']}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								dataSource={dataSource}
								pagination={false}
								footer={() => (
									<Space>
										<b>Total Work Order Amount: {getCurrencyString(totalWorkOrderAmount, false)}</b>
										<b>Total Balance Payment: {getCurrencyString(totalBalanceAmount, false)}</b>
									</Space>
								)}
							>
								<Table.Column
									title='Work Order Name'
									dataIndex='workOrderName'
									key='workOrderName'
								/>
								<Table.Column
									title='Contractor Name'
									dataIndex='contractorName'
									key='contractorName'
								/>
								<Table.Column
									title='Work Type'
									dataIndex='workType'
									key='workType'
								/>
								<Table.Column
									title='Work Order Status'
									dataIndex='workOrderStatus'
									key='workOrderStatus'
								/>
								<Table.Column
									title='Total Work Order Amount'
									dataIndex='totalAmount'
									key='totalAmount'
									render={(totalAmount: number) =>
										getCurrencyString(totalAmount, false)
									}
								/>
								<Table.Column
									title='Balance Payment'
									dataIndex='balanceAmount'
									key='balanceAmount'
									render={(balanceAmount: number) =>
										getCurrencyString(balanceAmount, false)
									}
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
