import {baseUrl, storageUrl} from './ui-env-const';

export const minDate = '1-1-1970';
export const nameChangeDate = '2024-03-31T23:59:59';
export const appAdmin = 'Ruchika Singh <ruchika.singh@prithu.in>';

export const lbBaseUrl = String(baseUrl);
export const s3Url = storageUrl;
export const idNotExist = 0;
export const none = 'NONE';
export const queryEscalateAfterDays = 3;
export const materialQueryEscalateAfterDays = 3;

export const notApplicable = 'Not Applicable';

export const clientScheduleExportMinRow = 0;
export const clientScheduleExportMaxRow = 30;
export const clientScheduleExportMinCol = 0;
export const clientScheduleExportMaxCol = 3;
export const parentTab = {
	drawings: 'Drawings',
	quantity: 'Quantity',
	designProgressTracker: 'Design Progress Tracker',
};
export const materialQueryLeadTimeSheetLink
  = 'https://docs.google.com/spreadsheets/d/1_4uaD8RjT-pEtqz9UsMfiEJ46Sra4Mh9TSBZRwgrvmo';

export const workOrderQueryLeadTimeSheetLink
  = 'https://docs.google.com/spreadsheets/d/1hDMw8gIE5LCb5FMHwxnAFAT0cWy4FJ3TtE1ZFh6fIwo';

export const siteResourceDirectorySheetLink
  = ' https://docs.google.com/spreadsheets/d/1NJ_xDiUaX_UfcIKHVqHl9U0RsBlKRMbozJ6CRwPi2d4';

export const pdfReportFile = {
	paidExpense: 'report-files/paid-expense-report.pdf',
	// C pendingExpenseAction: 'report-files/pending-expense-action-report.pdf',
	workOrder: 'report-files/work-order-report.pdf',
	weeklyWorkOrderBill: 'report-files/weekly-work-order-bill-report.pdf',
	totalWorkOrderBill: 'report-files/total-work-order-bill-report.pdf',
	weeklyExpenseSum: 'report-files/weekly-expense-sum-report.pdf',
	totalExpenseSum: 'report-files/total-expense-sum-report.pdf',
};

export const retoolIds = {
	pendingRoleAssignmentReport: 'eea299fd-6a4f-4e98-8d90-6ec25d8dfb4b',
	projectBudgetQuantities: 'ca3de324-f3fb-4395-aad5-7cc9bfcb8ef5',
	mastersPermission: '3026cfa8-0bc8-4f21-ae67-0eb2a2c091d8',
};
export const uiPaths = {
	login: 'login',
	dummi: 'dummi',
	reports: 'reports',
	projectScheduleDelayReport: 'project-schedule-delay-report',
	dailyProjectScheduleDelayReport: 'daily-project-schedule-delay-report',
	dailyProjectScheduleDelayByStatusReport: 'daily-project-schedule-delay-by-status-report',
	projectScheduleNotCompletedDelayReport: 'project-schedule-not-completed-delay-report',
	projectScheduleActivityDelayReport:
    'project-schedule-activity-delay-report',
	approvalScheduleActivityDelayReport:
    'approval-schedule-activity-delay-report',
	monthlyPettyCashReport: 'monthly-petty-cash-report',
	projectQueryReport: 'project-query-report',
	corporateQueryReport: 'corporate-query-report',
	pendingRoleAssignmentReport: 'pending-role-assignment-report',
	oragnisationalClientQueryReport: 'client-query-report',
	checklistResponseReport: 'checklist-response-report',
	drawingScheduleChecklistResponseReport: 'drawing-schedule-checklist-response-report',
	clientQueryReportForClients: 'client-query-report-for-clients',
	clientQueryDeviceUsageReport: 'client-query-device-usage-report',
	clientQueryOverdueReport: 'client-query-overdue-report',
	checklistApprovalReport: 'checklist-approval-report',
	paymentMilestoneReport: 'payment-milestone-report',
	drawingScheduleActivityReport: 'drawing-schedule-activity-report',
	dailyDrawingScheduleActivityReport: 'daily-drawing-schedule-activity-report',
	dailyLabourReport: 'daily_labour_report',
	emailRedirect: 'email-redirect',
	logout: 'logout',
	forgotPassword: 'forgot-password',
	changePassword: 'change-password',
	editProfile: 'edit-profile',
	home: 'home',
	profile: 'profile',
	userDetail: 'user-detail/:userId',
	notification: 'notification',
	approvedExpenseReport: 'approved-expense-report',
	approvedPaidExpense: 'approved-paid-expense/:paidExpenseId',
	expenseSummary: 'expense-summary',
	query: 'query',
	queryDetail: 'query-detail/:queryId',
	clientQuery: 'client-query',
	clientQueryDetail: 'client-query-detail/:id',
	corporateQuery: 'corporate-query',
	corporateQueryDetail: 'corporate-query-detail/:id',
	workOrder: 'work-order',
	editProjectHandoverSchedule: 'edit-project-handover-schedule/:siteId',
	viewProjectHandoverSchedule: 'project-handover-schedule',
	workOrderTbd: 'work-order-tbd',
	workOrderDetail: 'work-order-detail/:orderId',
	workOrderTerminate: 'work-order-terminate/:orderId',
	workOrderBalanceReport: 'work-order-balance-report',
	editEmailTemplate: 'edit-email-template/:id',
	createEmailTemplate: 'create-email-template',
	workOrderBill: 'work-order-bill',
	workOrderBillDetail: 'work-order-bill-detail/:id',
	workOrderBillPrint: 'work-order-bill-print/:id',
	materialQuery: 'material-query',
	queryReport: 'query-report',
	materialQueryDetail: 'material-query-detail/:queryId',
	expenseReport: 'expense-report',
	projectBudgetQuantities: 'project-budget-quantities',
	allProjectBudgetQuantities: 'all-project-budget-quantities',
	projectBudgetQuantityForm: 'project-budget-quantity/:id',
	pettyCash: 'petty-cash',
	pettyCashDetail: 'petty-cash-detail/:id',
	enterExpenseReport: 'enter-expense-report',
	wallet: 'wallet',
	myWallet: 'my-wallet',
	clientDrawingSchedule: 'client-drawing-schedule',
	drawingProcessSchedule: 'drawing-process-schedule',
	drawingProcessScheduleForm: 'drawing-process-schedule-form/:siteId/:tabId',
	clientDrawingProcessScheduleDetail:
    'client-drawing-process-schedule-detail/:siteId/:tabId',
	editDrawingProcessSchedule: 'edit-drawing-process-schedule/:id',
	user: 'user',
	site: 'site',
	viewHelpAndFeedback: 'view-help-and-feedback',
	paidExpense: 'paid-expense',
	siteDetail: 'site-detail/:siteId',
	enterPaymentDetail: 'enter-payment-detail/:userId',
	clientScheduleSheetData: 'client-schedule-sheet-data',
	createWorkOrderFromSchedule:
    'create-work-order-from-schedule/:scheduleId',
	allWorkOrder: 'all-work-order',
	changeLog: 'change-log',
	projectProcessMaster: 'project-process-master',
	createProjectProcessMaster: 'create-project-process-master/:type',
	editProjectProcessMaster: 'edit-project-process-master/:id',
	clientProjectProcessScheduleDetail:
    'client-project-process-schedule-detail/:id',
	projectProcessSchedule: 'project-process-schedule',
	materialOrderDailyQuantity: 'material-order-daily-quantities',
	materialOrderDailyQuantityForm:
    'material-order-daily-quantity-form/:masterId/:siteId',
	constructionSchedule: 'construction-schedule',
	clientConstructionSchedule: 'client-construction-schedule',
	approvalSchedule: 'approval-schedule',
	clientApprovalSchedule: 'client-approval-schedule',
	createProjectProcessSchedule: 'create-project-process-schedule',
	editProjectProcessSchedule: 'edit-project-process-schedule/:id',
	warrantyDocuments: 'warranty-documents',
	clientWarrantyDocuments: 'client-warranty-documents',
	editWarrantyDocument: 'edit-warranty-document/:id',
	warrantyDocumentDetail: 'warranty-document-detail/:id',
	viewProcessChecklistResponseDetail:
    'process-checklist-response-detail/:scheduleId/:drawingScheduleId/:checklistId/:siteId/:processType',
	viewProcessChecklistResponse: 'process-checklist-response',
	viewConstructionChecklistResponse: 'construction-checklist-response',
	viewDesignChecklistResponse: 'design-checklist-response',
	viewEmailTemplates: 'email-templates',
	electricityConsumption: 'electricity-consumption',
	electricityConsumptionReport: 'electricity-consumption-report',
	designProgressTrackerReport: 'design-progress-tracker-report',
	electricityConsumptionForm: 'electricity-consumption-form/:siteId',
	processChecklist: 'process-checklist',
	createProcessChecklist: 'create-process-checklist',
	editProcessChecklist: 'edit-process-checklist/:id',
	approval: 'approval',
	checklistResponseApproval: 'checklist-response-approval',
	allWorkOrderBill: 'all-work-order-bill',
	allMaterialQuery: 'all-material-query',
	retool: 'retool/:id',
	attendance: 'attendance',
	scheduleManualEntry: 'schedule-manual-entry',
	labourAttendance: 'labour-attendance',
	labourAttendanceForm: 'labour-attendance-form',
	createWallet: 'create-wallet',
	editWallet: 'edit-wallet/:walletId',
	addWalletMoney: 'add-money-wallet/:walletId',
	createNewUser: 'create-new-user',
	editUser: 'edit-user/:userId',
	createNewSite: 'create-new-site',
	editSite: 'edit-site/:siteId',
	activityTemplates: 'activity-templates',
	addActivityTemplate: 'add-activity-template',
	editActivityTemplate: 'edit-activity-template/:id',
	activityTemplateItems: 'activity-template-items/:templateId',
	addActivityTemplateItem: 'add-activity-template-item/:templateId',
	editActivityTemplateItem: 'edit-activity-template-item/:id',
	projectActivities: 'project-activities',
	addProjectActivity: 'add-project-activity/:siteId',
	editProjectActivity: 'edit-project-activity/:id',
	addPermissions: 'add-permissions',
	allPermissions: 'all-permissions',
	allCategories: 'all-categories',
	addCategory: 'add-category',
	editCategory: 'edit-category/:id',
	workOrderQuery: 'work-order-query',
	approvalWorkOrderQuery: 'approval-work-order-query',
	workOrderQueryDetail: 'work-order-query-detail/:id',
	constructionSchedulePhotoReport:
    'construction-schedule-photo-report',
};
