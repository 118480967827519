import {type ProcessChecklist} from 'app/models/process-checklist.model';
import {type WorkActivityMaster} from 'app/models/work-activity-master';
import {type DrawingSchedule} from 'app/models';
import {type ProjectProcessSchedule} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {type WorkOrderSchedule} from 'app/models';

export type ProjectProcessMaster = {
	id: number;
	parentId?: number;
	processName: string;
	projectProcessMasterIds?: string;
	activityName?: string;
	workActivityMasterId?: number;
	secondaryWorkActivityMasterIds?: string;
	budgetFieldName?: string;
	budgetWorkActivityMasterIds?: string;
	floorName?: string;
	sequence?: number;
	description?: string;
	beforeDays?: number;
	afterDays?: number;
	processType?: string;
	processDocument?: string;
	referenceDocuments?: string;
	milestoneNames?: string;
	projectProcessType: ProjectProcessType;

	dateType?: DateType;
	formulaType?: FormulaType;
	operatorType?: OperatorType;
	noOfDays?: number;
	durationDays?: number;
	dateFetchType?: DateFetchType;
	isInternal?: boolean;

	workActivityMaster?: WorkActivityMaster;
	processChecklists?: ProcessChecklist[];
	designSchedule?: DrawingSchedule;
	constructionSchedule?: ProjectProcessSchedule;
	relatedIds?: number[];
	relationProcessMasters?: ProjectProcessMaster[];
	parentProcessMaster?: ProjectProcessMaster;

	projectSchedule?: ProjectProcessSchedule;
	drawingSchedule?: DrawingSchedule;
	orderSchedule?: WorkOrderSchedule;
};

export enum ProjectProcessType {
	CONSTRUCTION = 'CONSTRUCTION',
	DESIGN = 'DESIGN',
	ORDER = 'ORDER',
	APPROVALS = 'APPROVALS',
	WARRANTY = 'WARRANTY',
}

export enum FormulaType {
	MIN = 'MIN',
	MAX = 'MAX',
	// EQUAL = 'EQUAL',
}

export enum DateType {
	START = 'START',
	END = 'END',
}

export enum OperatorType {
	PLUS = 'PLUS',
	MINUS = 'MINUS',
}

export enum DateFetchType {
	ACTUAL = 'ACTUAL',
	PLANED = 'PLANED',
}
