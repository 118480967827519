/* eslint-disable complexity */
import React, {useState} from 'react';
import {
	Col,
	Empty,
	Row,
	Spin,
	Button,
	Card,
	message,
	Space,
	Select,
	Form,
	Input,
	Modal,
	Badge,
	Descriptions,
} from 'antd';
import {
	CommentParentType,
	ModuleName,
	type Site,
	moduleCommentType,
} from 'app/models';
import {CommentBlock} from '../Common/CommentBlock';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {
	equalNum,
	getHrefLink,
	getPermissionSites,
	parseNum,
} from 'app/helpers';
import {approve, cancel, getById, update} from './actions';
import {
	get as getComments,
	save as saveComment,
} from 'app/components/Common/comment-actions';
import {uiPaths} from 'app/constants';
import type Types from 'MyTypes';
import {workOrderQueryOrderTypeLabel, WorkOrderQueryStatus, workOrderQueryStatusLabel} from './work-order-query.model';

const modelTypeOptions = {
	approve: 'Approve Query',
	cancel: 'Cancel Query',
	resolve: 'Resolve Query',
	updateStatus: 'Update Status',
	startWork: 'Start Work',
};

type ParamType = {
	id?: string;
};

export const ViewWorkOrderQueryDetail: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();
	const queryId = parseNum(params?.id ?? '0');
	const {user: authUser, userOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {loading, byIds, dataUpdated} = useSelector(
		(state: Types.RootState) => state.workOrderQuery,
	);
	const {
		byModule: permissions,
		allSites,
	} = useSelector((state: Types.RootState) => state.userPermission);
	const {
		allIds: commentAllIds,
		byIds: commentByIds,
		dataUpdated: commentDataUpdated,
	} = useSelector((state: Types.RootState) => state.comment);

	const [cancelReason, setCancelReason] = useState<string>('');
	const [modelType, setModelType] = useState<string>('');
	const [status, setStatus] = useState<string>('');
	const resolveSites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_QUERY, 'RESOLVE', allSites);
	const updateStatusSites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_QUERY, 'UPDATE_STATUS', allSites);
	const startWorkSites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER_QUERY, 'START_WORK', allSites);

	// Const filteredComments = comments?.length
	// 	? comments
	// 		.filter(
	// 			comment =>
	// 				comment.createdBy === parseNum(authUser?.id),
	// 		)
	// 		.sort((a, b) => {
	// 			if (a.createdAt && b.createdAt) {
	// 				return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
	// 			}

	// 			return -1;
	// 		})
	// 	: [];

	// React.useEffect(() => {
	// 	if (queryId) {
	// 		dispatch(getById(queryId));
	// 		dispatch(
	// 			getComments({
	// 				where: {
	// 					parentId: queryId,
	// 					parentType: CommentParentType.WORK_ORDER_QUERY,
	// 				},
	// 			}),
	// 		);
	// 	}
	// }, [queryId]);

	React.useEffect(() => {
		dispatch(getById(queryId));
		dispatch(
			getComments({
				where: {
					parentId: queryId,
					parentType: CommentParentType.WORK_ORDER_QUERY,
				},
			}),
		);
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Data updated successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (commentDataUpdated) {
			dispatch(getById(queryId));
			dispatch(
				getComments({
					where: {
						parentId: queryId,
						parentType: CommentParentType.WORK_ORDER_QUERY,
					},
				}),
			);
		}
	}, [commentDataUpdated]);

	const onSaveComment = data => dispatch(saveComment(data));

	const handleModelSubmit = e => {
		const query = byIds[queryId];
		if (modelType === modelTypeOptions.cancel) {
			if (!cancelReason) {
				void message.error('Enter cancellation reason');
				return;
			}

			dispatch(cancel(queryId, {cancelReason}));
		} else if (modelType === modelTypeOptions.approve) {
			dispatch(approve(queryId, {}));
		} else if (modelType === modelTypeOptions.resolve) {
			if (query.status !== WorkOrderQueryStatus.DRAWING_NOT_AVAILABLE) {
				void message.error('No need to resolve.');
				return;
			}

			dispatch(
				update(queryId, {status: WorkOrderQueryStatus.RESOLVED}),
			);
		} else if (modelType === modelTypeOptions.updateStatus) {
			if (query.status === WorkOrderQueryStatus.PENDING) {
				void message.error('Can not update status. Query is still pending.');
				return;
			}

			if (query.status === WorkOrderQueryStatus.WORK_STARTED) {
				void message.error('Can not update status. Work started.');
				return;
			}

			dispatch(update(queryId, {status}));
		} else if (modelType === modelTypeOptions.startWork) {
			if (query.status !== WorkOrderQueryStatus.WORK_ORDER_APPOINTED) {
				void message.error('Can not start work. Work order not appointed');
				return;
			}

			dispatch(
				update(queryId, {status: WorkOrderQueryStatus.WORK_STARTED}),
			);
		}
	};

	const query = byIds[queryId];
	const comments = commentAllIds?.map(id => commentByIds[id]);
	const canApprove = equalNum(query?.nextApprovedBy, authUser?.id);
	const canResolve = resolveSites.find(({id}) => equalNum(id, query?.siteId));
	const canUpdateStatus = updateStatusSites.find(({id}) => equalNum(id, query?.siteId));
	const canStartWork = startWorkSites.find(({id}) => equalNum(id, query?.siteId));

	if (!query) {
		return (
			<DefaultLayout>
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			</DefaultLayout>
		);
	}

	let statusColor = '';
	if (query.status === WorkOrderQueryStatus.APPROVED) {
		statusColor = 'cyan';
	} else if (query.status === WorkOrderQueryStatus.WORK_STARTED) {
		statusColor = 'green';
	} else if (query.status === WorkOrderQueryStatus.WORK_ORDER_APPOINTED) {
		statusColor = 'blue';
	} else if (query.status === WorkOrderQueryStatus.CANCELED) {
		statusColor = 'red';
	} else {
		statusColor = 'black';
	}

	return (
		<DefaultLayout currentPath={uiPaths.workOrderQueryDetail}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Badge.Ribbon
					style={{height: 25}}
					color={statusColor}
					text={query.status ? workOrderQueryStatusLabel[query.status] : ''}
				>
					<Card size='small'>
						<Row>
							<Col span={24}>
								<Button onClick={() => {
									history.goBack();
								}}>Back</Button>
								<h3 style={{textAlign: 'center'}}>{query.site?.name}</h3>
							</Col>
							<Col span={24}>
								<Descriptions column={1}>
									{query.workOrder ? (
										<Descriptions.Item label='Work Order'>
											<a
												target='_blank'
												href={getHrefLink(uiPaths.workOrderDetail, query.workOrder.id)} rel='noreferrer'
											>
												{query.workOrder.title}
											</a>
										</Descriptions.Item>
									) : []}

									<Descriptions.Item label='Work Order Type'>
										{query.workOrderType ? workOrderQueryOrderTypeLabel[query.workOrderType] : ''}
									</Descriptions.Item>

									<Descriptions.Item label='Work Order Category'>
										{query.subCategory?.name ?? ''}
									</Descriptions.Item>
								</Descriptions>
							</Col>
							<Col span={24} className='mt-15'>
								<Card size='small'>
									<CommentBlock
										parentId={query.id}
										parentType={moduleCommentType.WORK_ORDER_QUERY}
										authUser={authUser}
										comments={comments}
										canComment={true}
										saveComment={data => onSaveComment(data)}
									/>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col span={24} className='mt-15' style={{textAlign: 'right'}}>
								<Form>
									<Form.Item>
										<Space wrap={true}>
											{canApprove && query.nextApprovedUser
												&& query.status === WorkOrderQueryStatus.PENDING ? (
													<>
														<Button type='primary' onClick={() => {
															setModelType(modelTypeOptions.approve);
														}}>
															Approve
														</Button>
														<Button
															type='primary'
															danger={true}
															onClick={() => {
																setModelType(modelTypeOptions.cancel);
															}}
														>
															Cancel
														</Button>
													</>
												) : (
													[]
												)}

											{query.nextApprovedUser && query.status === WorkOrderQueryStatus.PENDING ? (
												<h4>
													{`Pending for Approval from ${query.nextApprovedUser?.name}`}
												</h4>
											) : query.createdBy === authUser?.id && query.nextApprovedUser ? (
												<h4>
													{`Pending for Approval from ${query.nextApprovedUser?.name}`}
												</h4>
											) : (
												[]
											)}
											{canResolve && query.status === WorkOrderQueryStatus.DRAWING_NOT_AVAILABLE ? (
												<Button type='primary' onClick={() => {
													setModelType(modelTypeOptions.resolve);
												}}>
													Mark this Query as Resolved
												</Button>
											) : (
												[]
											)}
											{canUpdateStatus && query.status !== WorkOrderQueryStatus.PENDING ? (
												<Button type='primary' onClick={() => {
													setModelType(modelTypeOptions.updateStatus);
												}}>
													Update Status
												</Button>
											) : (
												[]
											)}
											{canStartWork && query.status === WorkOrderQueryStatus.WORK_ORDER_APPOINTED ? (
												<Button type='primary' onClick={() => {
													setModelType(modelTypeOptions.startWork);
												}}>
													Start Work
												</Button>
											) : (
												[]
											)}
										</Space>
									</Form.Item>
								</Form>
							</Col>
						</Row>
					</Card>
				</Badge.Ribbon>

				<Modal
					title={modelType}
					width={700}
					open={Boolean(modelType)}
					onOk={handleModelSubmit}
					onCancel={e => {
						setModelType('');
						setStatus('');
						setCancelReason('');
					}}
				>
					<Form name='basic'>
						{modelType === modelTypeOptions.approve ? (
							<p>Approve this Query?</p>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.cancel ? (
							<Space direction='vertical' style={{width: '100%'}}>
								<p>Specify reason for cancelling</p>
								<Input.TextArea
									style={{width: '100%'}}
									rows={3}
									placeholder={'Enter Cancellation Reason'}
									defaultValue={cancelReason ?? ''}
									value={cancelReason ?? ''}
									onChange={(e: any) => {
										setCancelReason(String(e.target.value));
									}}
								/>
							</Space>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.resolve ? (
							<p>Resolve this Query?</p>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.updateStatus ? (
							<Space direction='vertical' style={{width: '100%'}}>
								<p>Select a status</p>
								<Select
									value={status}
									style={{width: '100%'}}
									placeholder='Select a status'
									onChange={value => {
										setStatus(value);
									}}
								>
									<Select.Option value={''}>
										Select a status
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.DRAWING_NOT_AVAILABLE}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.DRAWING_NOT_AVAILABLE]}
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.QUANTITY_UNDER_REVIEW}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.QUANTITY_UNDER_REVIEW]}
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.CONTRACTOR_TENDERING}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.CONTRACTOR_TENDERING]}
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.CONTRACTOR_DISCUSSION}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.CONTRACTOR_DISCUSSION]}
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.WORK_ORDER_UPLOAD}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.WORK_ORDER_UPLOAD]}
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.WORK_ORDER_PUBLISHED}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.WORK_ORDER_PUBLISHED]}
									</Select.Option>
									<Select.Option value={WorkOrderQueryStatus.WORK_ORDER_APPOINTED}>
										{workOrderQueryStatusLabel[WorkOrderQueryStatus.WORK_ORDER_APPOINTED]}
									</Select.Option>
								</Select>
							</Space>
						) : (
							[]
						)}

						{modelType === modelTypeOptions.startWork ? (
							<p>Start work?</p>
						) : (
							[]
						)}
					</Form>
				</Modal>
			</Spin>
		</DefaultLayout>
	);
};
